<template>
  <main class="table-container" v-if="tasks.length">
    <table class="my-table">
      <thead class="my-thead">
        <tr>
          <th class="my-th" style="min-width: 120px">
            <p class="-stb">Reference</p>
          </th>
          <th class="my-th" style="width: 38%; min-width: 300px">
            <p class="-stb">Task Title</p>
          </th>
          <th class="my-th" style="min-width: 170px">
            <p class="-stb">Responsible</p>
          </th>
          <th class="my-th" style="min-width: 100px">
            <p class="-stb">Status</p>
          </th>
          <th class="my-th" style="min-width: 110px">
            <p class="-stb">DeadLine</p>
          </th>
          <th
            class="my-th"
            style="width: 4%"
            v-show="selectedStages.length > 1"
          >
            <p class="-stb">Stage</p>
          </th>
          <th class="my-th" style="width: 4%"></th>
          <th class="my-th" style="width: 4%"></th>
        </tr>
      </thead>
      <tbody v-for="(task, index) in computedTasks" :key="task">
        <tr
          class="tr-content"
          :class="[index % 2 ? 'bg-primary' : 'bg-secondary']"
        >
          <td class="my-td">
            <p class="-stb">
              {{ task.task_ref }}
            </p>
          </td>
          <td class="my-td">
            <p class="-str">{{ getTasksObj[index].task_name }}</p>
          </td>
          <td class="my-td">
            <div class="flex-row" v-if="task.task_pers_user">
              <DynaBadge
                :picturePath="getUsers[index].avatar"
                :first_name="getUsers[index].first_name"
                :last_name="getUsers[index].last_name"
              />
              <div class="flex-column">
                <p class="-stb">{{ getAgents[index].agent_name }}</p>
                <p class="-str">
                  {{ getUsers[index].first_name }}
                  {{ getUsers[index].last_name }}
                </p>
              </div>
            </div>
            <div class="flex-row" v-else-if="getAgents[index]">
              <DynaBadge />
              <div class="flex-column">
                <p class="-stb">{{ getAgents[index].agent_name }}</p>
                <p class="-str">No user assigned</p>
              </div>
            </div>
            <div class="flex-row" v-else>
              <DynaBadge />
              <div class="flex-column">
                <p class="-str">Nothing assigned</p>
              </div>
            </div>
          </td>
          <td class="my-td">
            <div
              class="status-container"
              :class="getStatusStyle(getStatus[index].progress_status_name)"
            >
              <div class="status-toggler">
                <IconChevronDown
                  :strokeColor="getStatus[index].progress_status_name"
                />
              </div>
              <p class="-xstb">{{ getStatus[index].progress_status_name }}</p>
            </div>
          </td>
          <td class="my-td">
            <p class="-str">{{ getTaskDeadline[index] }}</p>
          </td>
          <td class="my-td" v-show="selectedStages.length > 1">
            <p
              class="-stb stage-list-badge"
              :style="`color: ${
                getStage(task.frame_stage_id).color_code
              }; border: 2px solid ${
                getStage(task.frame_stage_id).color_code
              };`"
            >
              {{ getStage(task.frame_stage_id).stage_value }}
            </p>
          </td>
          <td class="my-td" style="max-width: 40px">
            <IconLink title="Link to Deliverable" />
          </td>
          <td class="my-td" style="max-width: 40px">
            <IconEdit title="Edit Row" @click="setModal('update', task)" />
          </td>
        </tr>
      </tbody>
    </table>
  </main>
  <main class="no-data" v-else>
    <NoData :item="'task'" />
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import IconTrash from "../../../assets/icons/dynamicIcons/IconTrash.vue";
import IconEdit from "../../../assets/icons/dynamicIcons/IconEdit.vue";
import IconLink from "../../../assets/icons/dynamicIcons/IconLink.vue";
import IconChevronDown from "../../../assets/icons/dynamicIcons/IconChevronDown.vue";
import DynaBadge from "../../utils/DynaBadge.vue";
import NoData from "../../utils/NoData.vue";

export default {
  components: {
    IconTrash,
    IconEdit,
    IconLink,
    IconChevronDown,
    DynaBadge,
    NoData,
  },
  props: ["tasks"],
  data() {
    return {
      onBehalf: false,
    };
  },
  methods: {
    getStatusStyle(status) {
      const statusList = [
        "Ongoing",
        "Upcoming",
        "Awaiting Verification",
        "Completed",
      ];
      const filtered = statusList.filter((msg) => msg === status);

      return `status-${filtered.toString().replace(" ", "-").toLowerCase()}`;
    },
    getTaskStage(frame_stage_id) {
      const stage = this.$store.getters.stages.find(
        (stage) => stage.frame_stage_id == frame_stage_id
      );
      return stage.stage_value;
    },

    // will need mapGetters : user, selectedStages
    setModal(cmd, item) {
      this.$emit("setModal", cmd, item);
    },

    //delete this when backend is done
    getReference(n) {
      let numberAsString = n.toString();

      if (numberAsString.length < 3) {
        while (numberAsString.length < 3) {
          numberAsString = "0" + numberAsString;
        }
      }

      return numberAsString;
    },
    getStage(frame_stage_id) {
      const stage = this.$store.getters.stages.find(
        (stage) => stage.frame_stage_id == frame_stage_id
      );

      return stage;
    },
  },
  computed: {
    //for demo purposes only
    computedTasks() {
      //return the agents where the selected stages are included in the agent's stages property (array)
      return this.tasks.filter(({ frame_stage_id }) => {
        return this.selectedStages.includes(frame_stage_id);
      });
    },
    //
    ...mapGetters(["selectedStages"]),
    getUsers() {
      return this.computedTasks.map((task) => task.task_pers_user);
    },
    getTasksObj() {
      return this.computedTasks.map((task) => task.task);
    },
    getStatus() {
      return this.computedTasks.map((task) => task.progress_status);
    },
    getAgents() {
      return this.computedTasks.map((task) => task.task_pers_agent);
    },
    getTaskDeadline() {
      return this.computedTasks.map((task) => {
        return task.task_deadline ? task.task_deadline : "Deadline not set";
      });
    },
  },
};
</script>

<style scoped src="../../../assets/scopeListings.css">
.bg-primary {
  background: #f9fafb !important;
}
.bg-secondary {
  background: var(--white1) !important;
}
.table-container {
  width: 100%;
  height: auto;
}
.item-hover {
  cursor: pointer;
}
.my-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  transition: all 250ms ease;
}
.my-thead {
  /* background: var(--primary); */
  background-image: linear-gradient(
    to right,
    var(--primary) 0%,
    #004e92 51%,
    var(--primary) 100%
  );
  background-size: 500% auto;
}

.my-th {
  padding: 8px;
  color: var(--white1);
}
.my-th:first-child {
  border-top-left-radius: 8px;
}
.my-th:last-child {
  border-top-right-radius: 8px;
}
.my-td {
  padding: 8px;
}

/* .tr-content {
  transition: 100ms ease;
} */
.toggled-row {
  position: relative;
  box-shadow: 0px 8px 8px -6px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.tr-content:hover {
  background: rgb(243, 248, 254) !important;
}
.tr-toggled-content {
  /* border-block: 1px solid var(--gray5); */
  background: var(--gray6);
}
.tr-toggled-content:last-child {
  box-shadow: inset 0px -8px 8px -6px rgba(0, 0, 0, 0.1);
}
.flex-row {
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  align-items: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column p:last-child {
  color: var(--gray2);
}

.toggled {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 64px;
  padding-inline: 12px;
}
.toggled-text {
  color: var(--gray2);
}
/*ICONS*/
i {
  transform: scale(0.5);
  cursor: pointer;
}
.rotate {
  transform: rotateZ(180deg) scale(0.5);
}

thead {
  position: sticky;
  top: 0;
  z-index: 2;
}
</style>
